import React from 'react'
import { Link } from 'gatsby'

import { Col, Container, Row } from 'reactstrap'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCommentAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import styled from 'styled-components'
import scssVars from '../scss-variables'

import instagramIcon from '../../img/home/instagram.svg'
import facebookIcon from '../../img/home/facebook.svg'
import twitterIcon from '../../img/home/twitter.svg'

import MailingListForm from './MailingListForm'
import MenuSection from '../SiteHeader/MobileMenuSection'

library.add(faCommentAlt, faQuestionCircle)

const StyledContainer = styled(Container)`
  padding-bottom: 47px;
`

const ButtonRow = styled(Row)`
  padding-top: 33px;
`

const FooterButton = styled.button`
  padding: 17px 19px !important;
  line-height: 1 !important;
  font-size: 14px !important;
  &:hover, &:hover svg {
    transition: color 0.125s ease;
    color: white !important;
  }
`

const FooterAnchor = styled.a`
  padding: 17px 19px !important;
  line-height: 1 !important;
  font-size: 14px !important;
`

const StyledFooter = styled.footer`
  border-top: 4px solid #FA8200;
`

const PolicyLinks = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  padding-bottom: 15px;
  a {
    color: ${scssVars.white};
    font-weight: 600;
    font-size: 11px;
  }
`

const LegalText = styled.p`
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 11px;
`

const SocialMediaIcon = styled.img`
  max-width: 30px;
  max-height: 30px;
`

const MobileFooter = ({ locations, toggleFeedback }) => {
  const helpLinks = [
    { title: 'Contact Us', path: '/contact' },
    { title: 'Help Center', href: 'https://leatherspa.zendesk.com/hc/en-us' },
    { title: 'Accessibility', path: '/accessibility' },
    { title: 'Operational Policies', path: '/operational-policies' },
    { title: 'Virtual Repair', path: '/customer-services/virtual-repair' },
    { title: 'Corporate Services', path: '/customer-services/corporate' },
    { title: 'COVID-19 Updates', path: '/covid-19-updates' }
  ]
  const aboutLinks = [
    { title: 'About', path: '/about' },
    { title: 'Testimonials', path: '/testimonials' },
    { title: 'Press', path: '/press' },
    { title: 'Sustainability', path: '/sustainability' },
    { title: 'Partnerships', path: '/partnerships' },
    { title: 'Made In NYC', href: 'https://madeinnyc.org/company/leather-spa/' },
    { title: 'Licensed Stores', path: '/licensed-stores' }
  ]
  return (
    <StyledFooter className="footer bg-clr-sambuca text-light">
      <div className="d-lg-none">
        <div>
          <MenuSection title="Stores" links={locations}/>
          <MenuSection title="Get Help" links={helpLinks}/>
          <MenuSection title="Company" links={aboutLinks}/>
          <MailingListForm />
        </div>
        <StyledContainer>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <a href="https://instagram.com/leatherspa/" target="_blank" rel="me noopener noreferrer">
                <SocialMediaIcon src={instagramIcon} alt="Instagram logo" className="ml-3 mr-3"/>
              </a>
              <a href="https://www.facebook.com/leatherspa" target="_blank" rel="me noopener noreferrer">
                <SocialMediaIcon src={facebookIcon} alt="Facebook logo" className="ml-3 mr-3"/>
              </a>
              <a href="https://twitter.com/leatherspa" target="_blank" rel="me noopener noreferrer">
                <SocialMediaIcon src={twitterIcon} alt="Twitter logo" className="ml-3 mr-3"/>
              </a>
            </Col>
          </Row>
          <ButtonRow className="pt-4">
            <Col>
              <FooterButton onClick={toggleFeedback} className="btn btn-outline-primary px-0 w-100">
                <FontAwesomeIcon icon={faCommentAlt} color="#f7921e" style={{ width: '24px' }}/>FEEDBACK
              </FooterButton>
            </Col>
            <Col>
              <FooterAnchor href="https://leatherspa.zendesk.com/hc/en-us" target="_blank"
                className="btn btn-primary px-0 w-100" rel="me noopener noreferrer"
              >
                <FontAwesomeIcon icon={faQuestionCircle} color="white" style={{ width: '20px' }}/>HELP
              </FooterAnchor>
            </Col>
          </ButtonRow>
          <Row className="text-center">
            <PolicyLinks>
              <Link to="/terms-conditions">Terms &amp; Conditions</Link>&nbsp;/&nbsp;
              <Link to="/privacy">Privacy Policy</Link>&nbsp;/&nbsp;
              <Link to="/consumer-protection">CA Consumer Protection</Link>&nbsp;/&nbsp;
              <Link to="/sitemap.xml">Sitemap</Link>
            </PolicyLinks>
          </Row>
          <LegalText className="text-center text-primary">
            &copy; {new Date().getFullYear()} LEATHER SPA.
            <br/>
            All Rights Reserved Worldwide.
          </LegalText>
        </StyledContainer>
      </div>
    </StyledFooter>
  )
}

MobileFooter.propTypes = {
  locations: PropTypes.array.isRequired,
  toggleFeedback: PropTypes.func.isRequired
}

export default MobileFooter

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  Collapse, CardBody, CardHeader, Card
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import styled from 'styled-components'

import scssVars from '../scss-variables'

const StyledCard = styled(Card)`
  background-color: ${scssVars.darkBrown} !important;
  button {
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 20pxl
  }
`

const StyledCardHeader = styled(CardHeader)`
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 !important;
  background-color: ${scssVars.darkBrown};
  border-bottom: 1px solid rgba(235,233,228,0.25) !important;
  button {
    padding: 0.75rem 1.25rem !important;
    font-size: 16px;
    line-height: 1.2;
    &:hover, &:focus {
      text-decoration: none;
    }
    svg {
      line-height: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }
`

const StyledCollapse = styled(Collapse)`
`

const StyledCardBody = styled(CardBody)`
  padding: 0 1.25rem !important;
  .list-unstyled {
    margin-bottom: 0 !important;
    li {
      padding: 0.5rem 1.25rem;
      a {
        font-size: 14px;
      }
    }
  }
`

const MobileMenuSection = ({ title, links }) => {
  const [collapse, toggle] = useState(false)

  return (
    <StyledCard>
      <StyledCardHeader>
        <button className="btn btn-link text-left w-100" onClick={() => toggle(!collapse)}>
          <span>{title}</span>
          &nbsp;
          <FontAwesomeIcon className="float-right" icon={collapse ? faMinus : faPlus} style={{ color: 'rgba(235,233,228,0.25)' }} />
        </button>
      </StyledCardHeader>
      <StyledCollapse isOpen={collapse}>
        <StyledCardBody>
          <ul className="list-unstyled">
            {links.map((link, i) => (
              <li key={i}>
                {link.href
                  ? <a href={link.href} className="text-white" target="_blank" rel="noopener noreferrer">{link.title}</a>
                  : <Link to={link.path} className="text-white">{link.title}</Link>
                }
              </li>
            ))}
          </ul>
        </StyledCardBody>
      </StyledCollapse>
    </StyledCard>
  )
}

MobileMenuSection.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array
}

export default MobileMenuSection

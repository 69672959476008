export const calculateTotal = (items) => {
  const tickedItems = Object.entries(items).filter(([key, value]) => value)
  const total = tickedItems
    .map(([key, value]) => key.split('').pop())
    .reduce((acc, value) => acc + parseFloat(value), 0)
  const discount = 0

  return Math.max(0, total - discount)
}

export function parseItems (items) {
  const keys = ['id', 'description', 'price']
  return Object.entries(items).map(([key, value]) => key.split(':!_').reduce(
    (acc, value, index) => Object.assign({}, acc, { [keys[index]]: value }),
    {})
  )
}

export function parseLocation (location) {
  const keys = ['id', 'name']
  return location.split(':!_').reduce(
    (acc, value, index) => Object.assign({}, acc, { [keys[index]]: value }),
    {}
  )
}

export const validateEmail = (email) => {
  if (!email) {
    return 'Email required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return 'Invalid email address'
  }
}

export const openCalendlyModal = () => {
  // eslint-disable-next-line no-undef
  Calendly.initPopupWidget({
    url: 'https://calendly.com/leatherspa/30min?primary_color=fa8200'
  })
}

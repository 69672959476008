import React, { useState } from 'react'
import { Container, Navbar, Badge as BootstrapBadge, Carousel, CarouselItem } from 'reactstrap'
import styled from 'styled-components'
import { Link } from 'gatsby'

import MobileMenu from './Mobile'
import DesktopMenu from './Desktop'
import scssVars from '../scss-variables'

/* Container for the entire navbar including mobile and mobile side */
const StickyHeader = styled.header`
  box-shadow: 0 0 2px #8a8a8a;
  svg {
    fill: #f7921e;
    height: 100%;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    .top-row {
      position: relative;
      width: 100%;
      &.left-row, &.right-row {
        overflow: hidden;
        &:after {
          border-bottom: 1px solid ${scssVars.orange};
          position: absolute;
          width: 100vw;
          content: '';
          bottom: 0;
          z-index: 0;
        }
      }
      &.right-row:after {
        left: 0;
      }
      &.left-row:after {
        right: 0;
      }
    }
  }
`

const Preheader = styled.div`
  p {
    font-size: 0.9rem;
    line-height: 1.25;
  }
  .carousel-control-next,
  .carousel-control-prev {
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
    opacity: 1;
    width: 15px;
    height: 18px;
    padding-top: 2px;
  }
  .arrow-path {
    fill:none;
    stroke:#fff;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:2px;
    stroke:white;
  }
  a {
    font-family: 'EB Garamond', serif;
    text-decoration: underline;
  }
  .container {
    @media only screen and (min-width: 992px) {
      max-width: 1140px;
    }
    @media only screen and (min-width: 1200px) {
      max-width: 1280px;
    }
  }
  @media only screen and (min-width: 992px) {
    .carousel-inner {
      width: 45%;
      margin: 0 auto;
      overflow: hidden;
      word-wrap: nowrap;
    }
    .carousel-control-prev {
      left: 25%;
    }
    .carousel-control-next {
      right: 25%;
    }
  }
`

const Badge = styled(BootstrapBadge)`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 0.25em 0.5em;
  vertical-align: inherit;
`

const slides = [(
  <p className="w-100 text-center mb-0">
    <Badge color="primary">NEW</Badge> Drop off repairs at The UPS Store. <Link to="/repair-wizard">Start A Repair</Link>
  </p>
), (
  <p className="w-100 text-center mb-0">
    Schedule a one-on-one Virtual Repair. <Link to="/customer-services/virtual-repair">Book Now</Link>
  </p>
), (
  <p className="w-100 text-center mb-0">
    Free shipping on product orders over $50. <a href="https://shop.leatherspa.com/" target="_blank" rel="noopener noreferrer"> Shop Now</a>
  </p>
)]

const SiteHeader = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  return (
    <StickyHeader className="sticky-top">
      <Preheader className="d-flex flex-column p-0 bg-clr-sambuca">
        <Container className="text-light pt-1 pb-2">
          <Carousel activeIndex={activeIndex} interval={5000} next={next} previous={previous}>
            {slides.map((slide, i) => <CarouselItem key={i}>{slide}</CarouselItem>)}
            <a className="carousel-control-prev" role="button" onClick={previous}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(-22240.004 -12677.111) rotate(180)"/></svg>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" role="button" onClick={next}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(22257 12709.11)"/></svg>
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </Container>
      </Preheader>
      <Navbar color='white' light expand="lg" className="bg-light p-0">
        <Container className="text-uppercase" fluid style={{ padding: 0, maxWidth: 1280, maxHeight: 100 }}>
          <MobileMenu />
          <DesktopMenu />
        </Container>
      </Navbar>
    </StickyHeader>
  )
}

export default SiteHeader

import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useStaticQuery, graphql } from 'gatsby'
import { Row } from 'reactstrap'
import { Link }  from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MenuSection from './MobileMenuSection'
import { CartIcon } from './CartIcon'
import Monogram from '../../img/Monogram.svg'

const MenuLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  &:hover {
    text-decoration: none;
  }
`

const shopLinks = [
  { title: 'Shop All', href: 'https://shop.leatherspa.com/s/shop' },
  { title: 'Care', href: 'https://shop.leatherspa.com/shop/care/2' },
  { title: 'Comfort', href: 'https://shop.leatherspa.com/shop/comfort-/3' },
  { title: 'Accessories & Gifts', href: 'https://shop.leatherspa.com/shop/accessories-and-gifts/4' }
]

const aboutLinks = [
  { title: 'About', path: '/about' },
  { title: 'Press', path: '/press' },
  { title: 'Testimonials', path: '/testimonials' },
  { title: 'Partnerships', path: '/partnerships' },
  { title: 'Sustainability', path: '/sustainability' },
  { title: 'Store Licensing', path: '/licensed-stores' }
]

const MenuHeader = ({ className }) => (
  <div className={`${className} d-flex justify-content-between align-items-end pb-0`} style={{ outline: 'none' }}>
    <Link to="/" className="" title="Logo">
      <img src={Monogram} alt="Logo" style={{ width: '69px', height: '73px' }}/>
    </Link>
    <Link to="/account" className="text-brown" style={{ fontWeight: 500 }}>My Account</Link>
  </div>
)

MenuHeader.propTypes = {
  className: PropTypes.string
}

const MobileMenu = () => {
  const { site: { siteMetadata: { repairServices, customerServices } }, allMarkdownRemark: { edges } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            repairServices {
              path
              title
            }
            customerServices {
              path
              title
            }
          }
        }
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "location-page"}}}) {
          edges {
            node {
              frontmatter {
                path
                title
              }
            }
          }
        }
      }
    `
  )
  const locations = edges.map(location => location.node.frontmatter)
  return (
    <Row className="d-lg-none w-100 h-100 align-items-center" style={{ margin: 0 }}>
      <Menu className="h-100">
        <MenuHeader />
        <div className="text-center" style={{ paddingBottom: 10 }}>
          <Link to="/start-a-repair" className="btn btn-primary w-100">Start A Repair</Link>
        </div>
        <MenuSection title="Repair &amp; Care Services" links={repairServices}/>
        <MenuSection title="At Your Service" links={customerServices}/>
        <MenuSection title="Shop" links={shopLinks}/>
        <MenuSection title="About" links={aboutLinks}/>
        <MenuSection title="Locations" links={locations}/>
        <MenuLink to="/customer-services/virtual-repair">Virtual Repair Service</MenuLink>
      </Menu>
      <div className="col-4 offset-4 text-center">
        <Link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="42.252" viewBox="0 0 40 42.252">
            <g transform="translate(-26 -4243.001)">
              <path d="M47.354-177.921c0,.115.223-.085.321-.331.886-2.165,1.047-4.564-.283-5.99-3.343-3.589-9.931-4.35-12.325-5.335-2.649-1.089-5.448-2.521-6.239-5.571a8.714,8.714,0,0,1,.854-6.235c.239-.441,0-.352-.185-.174a8.288,8.288,0,0,0-2.3,4.6,9.723,9.723,0,0,0,.111,5.067c.868,2.673,4.177,4.247,6.124,5.012,1.731.716,3.005.606,8.469,2.527s5.5,5.364,5.457,5.98C47.358-178.373,47.359-178.223,47.354-177.921Zm-1.44-12.32,0,.006c-1.165-.493-2.417-.892-3.659-1.283-3.97-1.256-9.135-2.407-10.569-6.984a4.8,4.8,0,0,1-.026-3.619c.5-.918,1.55.069,2.035.424a10.042,10.042,0,0,0,6.552,2.186c2.071-.156,5.055-1.329,5.556-3.618a3.372,3.372,0,0,0-.939-3.3c-.394-.405-.565-.349-.212.1a2.686,2.686,0,0,1,.617,2.388c-.4,1.509-2.736,1.85-4.014,1.85-2.976,0-5.344-3.721-8.359-1.835a7.651,7.651,0,0,0-3.045,8.058c.66,3.083,3.588,4.484,6.237,5.571,2.394.987,8.981,1.747,12.327,5.336s-2.754,13.345-6.063,10.035c-.023-.016-.515-.44-.537-.459s-.026-.028-.038-.041a15.05,15.05,0,0,0-1.561-1.451,10.909,10.909,0,0,0-1.155-.823,1.679,1.679,0,0,0-.18-.1,9.668,9.668,0,0,0-3.848-1.288,9.11,9.11,0,0,0-7.154,2.925c-.25.283.153.578.637.264,1.479-.967,3.336-.744,4.8-.463,2.968.568,5.133,4.518,7.9,4.311a4.328,4.328,0,0,0,1.676-.656C59.49-182.132,48.9-189.191,45.914-190.241Z" transform="translate(14.004 4449.699)"/>
              <path d="M52.185-174.4c-.11-.062-.345.113-.345.113s-5.56,4.178-6.7,4.222-6.713-3.693-17.353-3.693a21,21,0,0,0-3.35.278,10.453,10.453,0,0,0,3.379-3.238c1.027-1.814,1.041-10.574.633-14.719-1.425-3.712.264-7.6,1.817-9.9a20.533,20.533,0,0,1,3.642-3.769c.069-.059.505-.456-.033-.154-2.1,1.179-5.825,1.91-7.448.956-3-1.77-5.037-2.232-6.772-2.232-2.247,0-4.847,2.206-4.79,4.518,0,.051-.01.1-.007.153A3.18,3.18,0,0,0,16.37-199.1c.553.367.588.179.055-.333a2.487,2.487,0,0,1-.836-1.8,2.488,2.488,0,0,1,3.284-2.309c3.179.6,3.54,2.053,5.553,2.455.018,0,.038,0,.058.007a7.753,7.753,0,0,0,5.032-.61A13.213,13.213,0,0,0,25-192.459s-.032,1.17-.026,1.247c.038.839-.544.6-1.254.669a8.475,8.475,0,0,0-5.1,2.226c-.206.209-.175.382.032.283,1.878-.888,6.332-1.226,6.417,2.035.131,5.107.831,10.306-3.561,13.182a21.114,21.114,0,0,0-3.083,1.13c-2.024.978-4.422,3.147-3.365,5.2.022.046.038.092.063.139A3.192,3.192,0,0,0,17.8-164.5c.629.07.818-.1.262-.234a2.534,2.534,0,0,1-1.8-1.359s-1.129-2.151,1.743-3.279A29.114,29.114,0,0,1,38.5-168.3a20.254,20.254,0,0,1,5,2.96.6.6,0,0,0,.374.121c.329,0,.414-.121.414-.121s7.111-8.047,8.016-8.7C52.288-174.039,52.431-174.206,52.185-174.4Z" transform="translate(11.197 4449.738)"/>
              <path d="M27.3-192.594c.233,4.481.1,11.1-.782,12.647a8.1,8.1,0,0,1-1.632,1.929c-.217.193-.191.41.075.291,2.084-.941,5.385-3.874,5.385-6.746l.078-5.4a9.784,9.784,0,0,1-3.125-2.716Z" transform="translate(13.5 4452.967)"/>
            </g>
          </svg>
        </Link>
      </div>
      <div className="col-2 offset-2">
        <ul className="navbar-nav ml-auto flex-row justify-content-end h-100">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              <CartIcon height="31px" width="30px"/>
              <span className="sr-only">Cart</span>
            </Link>
          </li>
        </ul>
      </div>
    </Row>
  )
}

export default MobileMenu

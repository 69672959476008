import React from 'react'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Helmet } from 'react-helmet'

import SiteHeader from './SiteHeader/SiteHeader'

import './all.scss'

import Footer from './Footer/Footer'

library.add(faFacebook, faInstagram, faTwitter)

const Layout = ({ children, useCalendly = false }) => (
  <>
    {useCalendly &&
      <Helmet>
        <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
        <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript"></script>
      </Helmet>
    }
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      <SiteHeader />
      <div style={{ flexGrow: '1' }}>{children}</div>
      <Footer />
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

export default Layout

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Col,
  Row,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'
import { Link }  from 'gatsby'

import { CartIcon } from './CartIcon'
import Logo from '../../../static/img/ls-logo@2x.png'
import styled, { css } from 'styled-components'
import scssVars from '../scss-variables'

import { useUserContext } from '../../providers/user'

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-top: -1px !important;
  min-height: 185px;
  padding: 20px 0 !important;
`

const NavbarBrand = styled(Link)`
  z-index: 1;
  background-color: white;
  img {
    margin: -5px 20px 0;
    height: 80px;
    width: 100px;
  }
`

const StyledDropdown = styled(Dropdown)`
  height: 48px;
  text-align: center;
`

const StyledDropdownToggle = styled(DropdownToggle)`
  padding: none !important;
  a {
    padding: 1rem;
    color: ${scssVars.darkBrown};
    &:hover, &:focus {
      color: ${scssVars.orange};
      text-decoration: none;
    }
  }
`

const UnstyledMainNavItem = ({ className, children, href }) => {
  const [isOpen, updateIsOpen] = useState(false)
  return href ? (
    <NavItem className={className} style={{ height: "50px" }}>
      <Link className="nav-link nav-anchor" to={href}>
        <span>{children}</span>
      </Link>
    </NavItem>
  ) : (
    <StyledDropdown
      nav
      inNavbar
      className={`${className} position-static`}
      onMouseOver={() => updateIsOpen(true)}
      onFocus={() => updateIsOpen(true)}
      onMouseLeave={() => updateIsOpen(false)}
      onBlur={() => updateIsOpen(false)}
      toggle={() => updateIsOpen(!isOpen)}
      isOpen={isOpen}
    >
      {children}
    </StyledDropdown>
  )
}

UnstyledMainNavItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  href: PropTypes.string
}

const MainNavItem = styled(UnstyledMainNavItem)`
  font-size: 12px;
  height: 100%;
  flex-grow: 1;
  .nav-anchor {
    height: 50px;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid white;
    color: black;
    transition: all 0.125s ease;
    &:hover {
      border-color: ${scssVars.orange};
      color: ${scssVars.orange};
    }
    &:focus {
      border-color: ${scssVars.orange};
    }
  }
`

const PrimaryNavItem = styled(MainNavItem)`
  .nav-anchor {
    background-color: ${scssVars.orange};
    border-color: ${scssVars.orange};
    color: white;
    &:hover {
      color: black;
    }
  }
`

const TopRowNavItem = styled(NavItem)`
  a {
    font-size: 12px;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
`

const sharedDropdownLinkStyles = css`
  display: block;
  text-transform: none;
  color: ${scssVars.darkBrown} !important;
  font-size: 12px;
  font-weight: ${props => props.bold ? '700 !important' : '500 !important'};
  letter-spacing: -0.0125em;
  &:hover, &:focus {
    text-decoration: underline;
  }
  span {
    color: ${scssVars.orange};
    font-weight: 600 !important;
    margin-left: 5px;
  }
`

const DropdownLink = styled.a`
  ${sharedDropdownLinkStyles}
`

const DropdownGatsbyLink = styled(Link)`
  ${sharedDropdownLinkStyles}
`

const DropdownMenuColumn = ({ className, column, bold, linkClassName }) => (
  <Col className={className}>
    {column.map((link, i) =>
      link.href
        ? <DropdownLink href={link.href} key={i} bold={bold ? 'true' : undefined} className={linkClassName || 'pb-2 btn-link'} target="_blank" rel="noopener noreferrer">
          {link.title}
          {link.hasNew && <span>NEW!</span>}
        </DropdownLink>
        : <DropdownGatsbyLink to={link.path} key={i} bold={bold ? 'true' : undefined} className={linkClassName || 'pb-2 btn-link'} >
          {link.title}
          {link.hasNew && <span>NEW!</span>}
        </DropdownGatsbyLink>
    )}
  </Col>
)

DropdownMenuColumn.propTypes = {
  className: PropTypes.string,
  column: PropTypes.array.isRequired,
  bold: PropTypes.bool,
  linkClassName: PropTypes.string
}

const DropdownImage = styled.img`
  width: 490px;
  height: 140px;
`

const DesktopMenu = () => {
  const { isAuthenticated } = useUserContext()
  return (
    <div className="d-none d-lg-flex w-100">
      <div className="w-100 d-none d-flex flex-column">
        <div className="d-flex flex-row top-row left-row">
          <TopRowNavItem>
            <Link to="/customer-services/corporate" className="nav-link top-bar">Corporate</Link>
          </TopRowNavItem>
          <div style={{ height: '50px' }}>{/* flex spacer */}</div>
        </div>
        <div className="d-flex flex-row bottom-bar">
          <PrimaryNavItem href="/start-a-repair">Start a Repair</PrimaryNavItem>
          <MainNavItem>
            <StyledDropdownToggle className="nav-anchor" tag="span" nav>
              <Link to="/repair-services">Repair &amp; Care Services</Link>
            </StyledDropdownToggle>
            <StyledDropdownMenu right className="text-left w-100">
              <Container className="px-0">
                <Row className="d-flex flex-row w-100">
                  <DropdownMenuColumn column={[{
                    title: 'Women\'s Shoes',
                    path: '/repair-services/womens-shoes'
                  }, {
                    title: 'Men\'s Shoes',
                    path: '/repair-services/mens-shoes'
                  }, {
                    title: 'Handbags',
                    path: '/repair-services/bags'
                  }, {
                    title: 'Wallets',
                    path: '/repair-services/wallets'
                  }, {
                    title: 'Belts',
                    path: '/repair-services/belts'
                  }, {
                    title: 'Garments',
                    path: '/repair-services/garments'
                  }]} bold={true} linkClassName="pb-1 btn-link" />
                  <DropdownMenuColumn column={[{
                    title: 'Heels',
                    path: '/repair-services/heel-repair'
                  }, {
                    title: 'Soles',
                    path: '/repair-services/soles'
                  }, {
                    title: 'Sole Guard',
                    path: '/repair-services/sole-guards'
                  }, {
                    title: 'Stretching',
                    path: '/repair-services/shoe-stretching'
                  }]} />
                  <DropdownMenuColumn column={[{
                    title: 'Stitching',
                    path: '/repair-services/stitching'
                  }, {
                    title: 'Zippers',
                    path: '/repair-services/zippers'
                  }, {
                    title: 'Dye',
                    path: '/repair-services/dye'
                  }, {
                    title: 'Cleaning & Conditioning',
                    path: '/repair-services/cleaning-conditioning'
                  }]} />
                  <DropdownMenuColumn column={[{
                    title: 'Alterations & Customization',
                    path: '/repair-services/alteration-customization'
                  }, {
                    title: 'Embossing & Monogramming',
                    path: '/repair-services/embossing-monogramming'
                  }]} />
                  <DropdownImage alt="TODO" src="https://leatherspa.imgix.net/Dropdown-image-Repair-Service@2x.png?fit=scale&w=980&fm=jpg" />
                </Row>
              </Container>
            </StyledDropdownMenu>
          </MainNavItem>
          <MainNavItem>
            <StyledDropdownToggle className="nav-anchor" tag="span" nav>
              <Link to="/customer-services">At Your Service</Link>
            </StyledDropdownToggle>
            <StyledDropdownMenu right className="text-left w-100">
              <Container className="px-0">
                <Row className="d-flex flex-row w-100">
                  <DropdownMenuColumn column={[{
                    title: 'Mail-in Service',
                    path: '/customer-services/mail-in'
                  }, {
                    title: 'On-Demand Pick-Up',
                    path: '/customer-services/pick-up'
                  }, {
                    title: 'Express Drop-off',
                    path: '/customer-services/drop-off'
                  }]} style={{ minWidth: '225px' }} />
                  <DropdownMenuColumn column={[{
                    title: 'Delivery Service',
                    path: '/customer-services/delivery'
                  }, {
                    title: 'Payment for Services',
                    path: '/customer-services/payment'
                  }, {
                    title: 'Virtual Repair Service',
                    path: '/customer-services/virtual-repair'
                  }, {
                    title: 'Corporate Services',
                    path: '/customer-services/corporate'
                  }]} />
                  <DropdownImage src="https://leatherspa.imgix.net/LS_Dropdown-image-At-Your-Service@2x.jpg?fit=crop&w=980&h=280" />
                </Row>
              </Container>
            </StyledDropdownMenu>
          </MainNavItem>
        </div>
      </div>
      <div className="d-flex flex-column flex-shrink justify-content-center">
        <NavbarBrand to="/" className="mr-auto">
          <img src={Logo} alt="Leather Spa" />
        </NavbarBrand>
      </div>
      <div className="w-100 d-flex flex-column flex-grow">
        <div className="d-flex flex-row-reverse top-row right-row" style={{ paddingRight: '1rem' }}>
          <CartIcon className="pl-1" />
          <TopRowNavItem>
            {isAuthenticated() === true && <Link className="nav-link top-bar" to="/account">My Account</Link>}
            {isAuthenticated() === false && <Link className="nav-link top-bar" to="/log-in">Log In</Link>}
          </TopRowNavItem>
        </div>
        <div className="d-flex flex-row flex-row-reverse">
          <MainNavItem href="/customer-services/virtual-repair">Virtual Repair</MainNavItem>
          <MainNavItem>
            <StyledDropdownToggle to="/locations" className="nav-anchor" tag="span" nav>
              <Link to="/locations">Locations</Link>
            </StyledDropdownToggle>
            <StyledDropdownMenu right className="text-left w-100">
              <Container className="px-0">
                <Row className="d-flex flex-row w-100">
                  <DropdownMenuColumn column={[{
                    title: 'The Plaza Hotel',
                    path: '/locations/plaza-hotel'
                  }, {
                    title: 'Midtown - 55th St.',
                    path: '/locations/55-w-55th-street'
                  }, {
                    title: 'Grand Central Terminal - Dining Concourse',
                    path: '/locations/grand-central-terminal'
                  }, {
                    title: 'Grand Central Terminal - Lexington Ave.',
                    path: '/locations/grand-central-lexington'
                  }, {
                    title: 'Downtown - FiDi',
                    path: '/locations/downtown-fidi'
                  }]} />
                  <DropdownMenuColumn column={[{
                    title: 'Saks Fifth Avenue',
                    path: '/locations/saks-fifth-avenue'
                  }, {
                    title: 'Repair Facility & LIC Store',
                    path: '/locations/repair-facility'
                  }, {
                    title: 'Contact Us',
                    path: '/contact'
                  }]} />
                  <DropdownImage className='dropDownImg' alt="TODO" src="https://leatherspa.imgix.net/Dropdown-image-Locations@2x.png?fit=scale&w=980&fm=jpg" />
                </Row>
              </Container>
            </StyledDropdownMenu>
          </MainNavItem>
          <MainNavItem>
            <StyledDropdownToggle className="nav-anchor" tag="span" nav>
              <Link to="/about">About</Link>
            </StyledDropdownToggle>
            <StyledDropdownMenu right className="text-left w-100">
              <Container className="px-0">
                <Row className="d-flex flex-row w-100">
                  <DropdownMenuColumn column={[{
                    title: 'About',
                    path: '/about'
                  }, {
                    title: 'Testimonials',
                    path: '/testimonials'
                  }, {
                    title: 'Press',
                    path: '/press'
                  }, {
                    title: 'Sustainability',
                    path: '/sustainability'
                  }, {
                    title: 'Partnerships',
                    path: '/partnerships'
                  }]} />
                  <DropdownMenuColumn column={[{
                    title: 'Made in NYC',
                    href: 'https://madeinnyc.org/company/leather-spa/'
                  }, {
                    title: 'Licensed Stores',
                    path: '/licensed-stores'
                  }]} />
                  <DropdownImage alt="TODO" src="https://leatherspa.imgix.net/Dropdown-image-About@2x.png?fit=scale&w=980&fm=jpg" />
                </Row>
              </Container>
            </StyledDropdownMenu>
          </MainNavItem>
          <MainNavItem>
            <StyledDropdownToggle className="nav-anchor" tag="span" nav>
              <a href="https://shop.leatherspa.com/" target="_blank" rel="noopener noreferrer">Shop</a>
            </StyledDropdownToggle>
            <StyledDropdownMenu right className="text-left w-100">
              <Container className="px-0">
                <Row className="d-flex flex-row w-100">
                  <DropdownMenuColumn column={[{
                    title: 'Shop All',
                    href: 'https://shop.leatherspa.com'
                  }, {
                    title: 'Care',
                    href: 'https://shop.leatherspa.com/shop/care/2'
                  }, {
                    title: 'Comfort',
                    href: 'https://shop.leatherspa.com/shop/comfort-/3'
                  }, {
                    title: 'Accessories & Gifts',
                    href: 'https://shop.leatherspa.com/shop/accessories-and-gifts/4'
                  }]} />
                  <DropdownImage alt="TODO" src="https://leatherspa.imgix.net/Dropdown-image-Shop@2x.png?fit=scale&w=980&fm=jpg" />
                </Row>
              </Container>
            </StyledDropdownMenu>
          </MainNavItem>
        </div>
      </div>
    </div>
  )
}

export default DesktopMenu

import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button, Alert } from 'reactstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'

import { SUBSCRIBE_MUTATION } from '~mutations/authentication'
import { validateEmail } from '~components/Shared/Functions'

import scssVars from '../scss-variables'

const FormWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    padding: 0;
    margin: 0;
  }
`

const FormHeader = styled.div`
  height: 48px;
  margin-top: 0;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0;
  button {
    padding: 0 !important;
    font-size: 20px;
    line-height: 24px;
  }
  h4 {
    color: ${scssVars.brightOrange};
    font-size: 16px;
    margin-bottom: 0;
  }
`

const FormField = styled(Field)`
  border: none !important;
  border-bottom: 1px solid white !important;
  background: none !important;
  padding: 0 !important;
  color: white !important;
  font-size: 14px !important;
  &::placeholder {
    color: #EBE9E4 !important;
  }
  &:focus {
    outline: none !important;
  }
`

const FormGroupWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`

const SubmitButton = styled(Button)`
  position: absolute;
  padding-right: 0 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: white !important;
  margin-bottom: 0;
  line-height: 100%;
  bottom: 0;
  right: 0;
  top: 0;
`

const MailingListForm = ({ className }) => {
  const [subscribe] = useMutation(SUBSCRIBE_MUTATION)
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async (values) => {
    await subscribe({ variables: values })
    setSubmitted(true)
  }

  return (
    <FormWrapper className={className}>
      <FormHeader>
        <h4>Mailing List</h4>
      </FormHeader>
      <Formik
        initialValues={{
          email: '',
          list: 'customers'
        }}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting }) => {
          if (submitted) {
            return (
              <p>Thank you for signing up. We look forward to sending you the latest LEATHER SPA news!</p>
            )
          } else {
            return (
              <Form>
                <FormGroupWrapper>
                  <FormField className="form-control w-100" type="email" name="email" placeholder="Email Address" validate={validateEmail} required />
                  <SubmitButton type="submit" disabled={isSubmitting}>
                    &#x2192;
                  </SubmitButton>
                </FormGroupWrapper>
                {!isValid && (
                  <>
                    {['email'].map((name, i) => (
                      <ErrorMessage name={name} key={i}>
                        {errMsg => <Alert color="danger">{errMsg}</Alert>}
                      </ErrorMessage>
                    ))}
                  </>
                )}
              </Form>
            )
          }
        }}
      </Formik>
    </FormWrapper>
  )
}

MailingListForm.propTypes = {
  className: PropTypes.string
}

export default MailingListForm

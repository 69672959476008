import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Container, Row, Button } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCommentAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


import scssVars from '../scss-variables'
import MailingListForm from './MailingListForm'
import instagramIcon from '../../img/home/instagram.svg'
import facebookIcon from '../../img/home/facebook.svg'
import twitterIcon from '../../img/home/twitter.svg'
import logo from '../../img/ls-monogram.svg'

library.add(faCommentAlt, faQuestionCircle)

const StyledContainer = styled(Container)`
  padding-top: 45px;
  padding-bottom: 45px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    max-width: 1280px !important;
  }
`

const StyledTitle = styled.h4`
  color: ${scssVars.brightOrange};
  font-size: 16px;
`

const StyledLink = styled(Link)`
  color: white;
`
const StyledAnchor = styled.a`
  color: white;
`

const StyledFooter = styled.footer`
  border-top: 4px solid ${scssVars.brightOrange};
  position: relative;
  bottom: 0;
  background-image: url('https://leatherspa.imgix.net/shield.svg');
  background-position: right -12.5px top 25%;
  background-repeat: no-repeat;
  background-size: 235px 85%;
  box-shadow: inset 0px 2px 5px 0px rgba(0,0,0,0.75);
`

const SplitCol = styled(Col)`
  display: flex;
  flex-direction:column;
  justify-content:flex-start;
`

const SocialMediaImg = styled.img`
  width: 23px;
  height: 23px;
`
const SocialMediaCol = styled(Col)`
  display: flex;
  justify-content:flex-start;
`

const StyledPrivacyRow = styled(Row)`
  display: flex;
  a {
    color: ${scssVars.brightOrange};
    font-weight: 400;
    margin-bottom: 5px;
    &:hover {
      color: ${scssVars.white};
    }
  }
`

const TopRow = styled(Row)`
  display: flex;
  justify-content: space-between;
`

const BottomRow = styled(Row)`
  display: flex;
`

const StyledList = styled.ul`
  li {
    line-height: 2em;
  }
`

const Copyright = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
`

const ToggleButton = styled(Button)`
  padding: 0 !important;
  text-transform: none !important;
  font-size: 14px !important;
  line-height: 1.42 !important;
  margin-bottom: 5px;
  color: ${scssVars.brightOrange} !important;
`

const DesktopFooter = ({ locations, toggleFeedback }) => (
  <StyledFooter className="footer bg-clr-sambuca text-light">
    <StyledContainer>
      <TopRow className="mx-0">
        <Col className="pl-0">
          <StyledTitle>Stores</StyledTitle>
          <StyledList className="list-unstyled">
            {locations.map((link, i) =>
              <li key={`footer-location-${i}`}>
                <Link to={link.path} key={i.toString()} className="text-white">
                  {link.title}
                </Link>
              </li>
            )}
          </StyledList>
        </Col>
        <Col>
          <StyledTitle>Get Help</StyledTitle>
          <StyledList className="list-unstyled">
            <li>
              <StyledLink to="/contact">Contact Us</StyledLink>
            </li>
            <li>
              <StyledAnchor href="https://leatherspa.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">Help Center</StyledAnchor>
            </li>
            <li>
              <StyledLink to="/accessibility">Accessibility</StyledLink>
            </li>
            <li>
              <StyledLink to="/operational-policies">Operational Policies</StyledLink>
            </li>
            <li>
              <StyledLink to="/customer-services/virtual-repair">Virtual Repair</StyledLink>
            </li>
            <li>
              <StyledLink to="/customer-services/corporate">Corporate Services</StyledLink>
            </li>
            <li>
              <StyledLink to="/covid-19-updates">COVID-19 Updates</StyledLink>
            </li>
          </StyledList>
        </Col>
        <Col>
          <StyledTitle>Company</StyledTitle>
          <StyledList className="list-unstyled">
            <li>
              <StyledLink to="/about">About</StyledLink>
            </li>
            <li>
              <StyledLink to="/testimonials">Testimonials</StyledLink>
            </li>
            <li>
              <StyledLink to="/press">Press</StyledLink>
            </li>
            <li>
              <StyledLink to="/sustainability">Sustainability</StyledLink>
            </li>
            <li>
              <StyledLink to="/partnerships">Partnerships</StyledLink>
            </li>
            <li>
              <StyledAnchor href="https://madeinnyc.org/company/leather-spa/" target="_blank" rel="noopener noreferrer">Made in NYC</StyledAnchor>
            </li>
            <li>
              <StyledLink to="/licensed-stores">Licensed Stores</StyledLink>
            </li>
          </StyledList>
        </Col>
        <SplitCol style={{ flexGrow: 1.5 }}>
          <div>
            <StyledTitle style={{ marginBottom: 12 }}>Follow us</StyledTitle>
            <Row>
              <SocialMediaCol>
                <a href="https://instagram.com/leatherspa/" target="_blank" rel="noopener noreferrer">
                  <SocialMediaImg src={instagramIcon} alt="Instagram logo"/>
                </a>
                <a href="https://www.facebook.com/leatherspa" target="_blank" rel="noopener noreferrer">
                  <SocialMediaImg src={facebookIcon} alt="Facebook logo" className="ml-2 mr-2"/>
                </a>
                <a href="https://twitter.com/leatherspa" target="_blank" rel="noopener noreferrer">
                  <SocialMediaImg src={twitterIcon} alt="Twitter logo"/>
                </a>
              </SocialMediaCol>
            </Row>
          </div>
          <div className="mt-5">
            <MailingListForm />
          </div>
        </SplitCol>
      </TopRow>
      <BottomRow className="mt-5 mx-0">
        <Col>
          <Row>
            <div className="logo mr-4">
              <img src={logo} width="85px" height="90px"/>
            </div>
            <Col className="mt-3">
              <StyledPrivacyRow>
                <Link to="/terms-conditions">Terms &amp; Conditions</Link>&nbsp;/&nbsp;
                <Link to="/privacy">Privacy Policy</Link>&nbsp;/&nbsp;
                <Link to="/consumer-protection">CA Consumer Protection</Link>&nbsp;/&nbsp;
                <Link to="/sitemap.xml">Sitemap</Link>
              </StyledPrivacyRow>
              {/* <Row>
                <button onClick={() => {
                  testFunctionThatDoesntExist()
                }}>TEST</button>
              </Row> */}
              <Row>
                <ToggleButton color="link" onClick={toggleFeedback}>Submit Website and/or Service feedback</ToggleButton>
              </Row>
              <Row>
                <Copyright>© {new Date().getFullYear()} LEATHER SPA. All Rights Reserved Worldwide.</Copyright>
              </Row>
            </Col>
          </Row>
        </Col>
      </BottomRow>
    </StyledContainer>
  </StyledFooter>
)

DesktopFooter.propTypes = {
  locations: PropTypes.array.isRequired,
  toggleFeedback: PropTypes.func.isRequired
}

export default DesktopFooter

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { useUserContext } from '../../providers/user'

import MobileMenu from './Mobile'
import DesktopMenu from './Desktop'

const SiteHeader = () => {
  const { isAuthenticated, getUser } = useUserContext()
  const { allMarkdownRemark: { edges } } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: {
              eq: "location-page"
            }
          }
        },
        sort: {
          order: ASC,
          fields: frontmatter___order
        }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
            }
          }
        }
      }
    }
  `)
  const toggleFeedback = () => {
    const user = getUser()
    window.zE('webWidget', 'open')
    if (isAuthenticated()) {
      window.zE('webWidget', 'identify', { name: user.name, email: user.email })
      window.zE('webWidget', 'prefill', {
        name: { value: user.name },
        email: { value: user.email }
      })
    }
  }

  const locations = edges.map((edge, i) => edge.node.frontmatter)
  return (
    <>
      <div className="d-lg-none">
        <MobileMenu locations={locations} toggleFeedback={toggleFeedback} />
      </div>
      <div className="d-none d-lg-block">
        <DesktopMenu locations={locations} toggleFeedback={toggleFeedback} />
      </div>
    </>
  )
}

export default SiteHeader
